.mask-container {
    position: relative;
    width: 1000px; /* Ajustez selon la taille de votre image */
}

.mask-container::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(
            circle 3vmax at var(--cursorX) var(--cursorY),
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, .5) 80%,
            rgba(0, 0, 0, .95) 100%
    );
}
.mask-container::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: radial-gradient(
            circle 3vmax at var(--cursorX) var(--cursorY),
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, .5) 80%,
            rgba(0, 0, 0, .95) 100%
    );
}
