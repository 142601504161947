* {box-sizing: border-box;}

.img-magnifier-container {
    position: relative;
}

.img-magnifier-glass {
    position: absolute;
    border: 3px solid #000;
    border-radius: 50%;
    cursor: none;
    /*Set the size of the magnifier glass:*/
    width: 150px;
    height: 150px;
}