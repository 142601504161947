.combination-lock-drag {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #414f6b;
    margin-left: 11px;
    background: -webkit-linear-gradient(top, #8c9093 0%,#b6babd 9%,#ccd2d6 18%,#ffffff 55%,#ccd2d6 82%,#b6babd 91%,#8c9093 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#8c9093),color-stop(9%, #b6babd),color-stop(18%, #ccd2d6),color-stop(55%, #ffffff),color-stop(82%, #ccd2d6),color-stop(91%, #b6babd),to(#8c9093));
    background: -o-linear-gradient(top, #8c9093 0%,#b6babd 9%,#ccd2d6 18%,#ffffff 55%,#ccd2d6 82%,#b6babd 91%,#8c9093 100%);
    background: linear-gradient(to bottom, #8c9093 0%,#b6babd 9%,#ccd2d6 18%,#ffffff 55%,#ccd2d6 82%,#b6babd 91%,#8c9093 100%);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    cursor: move;
}
.combination-lock-drag > ul {
    margin: 0;
    padding: 0;
}

.combination-lock-drag > ul > li {
    display: block;
    padding: 0 20px;
    line-height: 1.2;
}

.combination-lock {
    padding: 30px 30px;
    border: 1px solid #cfd3d6;
    background: #ccc;
    text-align: center;
    background: -webkit-linear-gradient(top, #eee 0%,#949ba0 100%);
    background: linear-gradient(to bottom, #eee 0%,#949ba0 100%);
    border-radius: 4px;
    margin: 0 auto;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.6);
    position: relative;
    display: inline-block;
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.combination-lock-container {
    padding: 0 20px 0 8px;
    border-radius: 7px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    -webkit-box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.9);
    box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.9);
    background: -webkit-linear-gradient(top, #c4c4c4 0%,#676767 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(#c4c4c4),to(#676767));
    background: -o-linear-gradient(top, #c4c4c4 0%,#676767 100%);
    background: linear-gradient(to bottom, #c4c4c4 0%,#676767 100%);
    overflow: hidden;
}

.combination-lock-open {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 5px;
    color: #fff;
    font-weight: bold;
    opacity: 0;
    -webkit-transition: 0.5s opacity;
    -o-transition: 0.5s opacity;
    transition: 0.5s opacity;
}

.combination-lock-open--show {
    opacity: 1;
}