@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Regular'), url(../fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins-Bold'), url(../fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Poppins, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.ant-carousel .slick-dots li button {
  margin-top: 20px;
  background: #14665F;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #14665F;
  margin-top: 20px;
}

.ant-notification {
  z-index: 9998 !important;
}