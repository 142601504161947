.custom-ant .ant-select-disabled {
    background-color: #e0e0e0 !important;
    border-radius: 5px !important;
}

.custom-ant .ant-select-selection-item {
    white-space: normal !important;
    text-align: center !important;
}

.custom-ant .ant-select-item-option-content {
    white-space: normal !important;
}